// Color palette
$color-blue-000: #e3f0ff;
$color-blue-100: #c9e2ff;
$color-blue-200: #93bfff;
$color-blue-300: #5ca7ff;
$color-blue-400: #328edf;
$color-blue-500: #357ed4;
$color-blue-600: #2769ba;
$color-blue-700: #1855a0;
$color-blue-800: #044187;
$color-green-100: #e4ffef;
$color-green-200: #97b6a4;
$color-green-300: #6fcf97;
$color-green-400: #53a674;
$color-green-600: #1e5a35;
$color-teal-000: #c6dedc;
$color-teal-100: #ccfbf1;
$color-teal-200: #99f6e4;
$color-teal-600: #0d9488;
$color-teal-700: #0f766e;
$color-grey-000: #fafafa;
$color-grey-100: #f4f4f5;
$color-grey-200: #e4e4e7;
$color-grey-300: #d4d4d8;
$color-grey-400: #a1a1aa;
$color-grey-500: #71717a;
$color-grey-600: #52525b;
$color-grey-700: #3f3f46;
$color-grey-800: #27272a;
$color-grey-900: #18181b;
$color-red-000: #f9cdcd;
$color-red-100: #fee2e2;
$color-red-200: #da584d;
$color-red-300: #eb5757;
$color-red-400: #af3c34;
$color-red-500: #86201c;
$color-red-600: #5e0000;
$color-purple-000: #e6dffc;
$color-purple-100: #a787f8;
$color-purple-200: #7e5cf5;
$color-purple-300: #6044c4;
$color-purple-400: #422e96;
$color-purple-500: #29186a;
$color-purple-600: #110341;
$color-yellow-000: #fffae4;
$color-yellow-100: #fff3c2;
$color-yellow-200: #ffdd57;
$color-yellow-500: #d6aa00;
$color-yellow-800: #92400e;
$color-orange-000: #ffead1;
$color-orange-200: #ffc98a;
$color-orange-300: #ff9e2c;
$color-orange-400: #e1861e;
$color-orange-600: #a65900;
$color-black: #09090b;
$color-white: #fff;
$color-transparent-blue: rgba(0, 104, 255, 0.1);
$color-transparent-black: rgba(61, 61, 65, 0.19);
$txt-primary: $color-grey-900;
$link-color: $color-blue-300;
$hover-color: $color-blue-000;
$color-bg-1: $color-white;
$color-critical-warning: $color-red-000;
$color-minor-warning: $color-orange-000;
